<template>
  <div>
    <div class="page inner-container login">
      <div class="page-wrap">
        <div class="row">
          <loginForm
            @openNewAccountProfileModal="handleOpenNewAccountProfileModal"
          ></loginForm>
          <resetForm></resetForm>
        </div>
      </div>
    </div>

    <newProfileModal
      :newAccountProfileModalOpened="newAccountProfileModalOpened"
      @closeNewAccountProfileModal="handleCloseNewAccountProfileModal"
    ></newProfileModal>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

import newProfileModal from "@/components/account/newProfileModal";
import loginForm from "@/views/Login/parts/loginForm";
import resetForm from "@/views/Login/parts/resetForm";
export default {
  name: "LoginIndex",
  components: { loginForm, resetForm, newProfileModal },
  data() {
    return {
      newAccountProfileModalOpened: false
    };
  },
  methods: {
    ...mapActions("common", ["setPreventScrolling"]),

    handleOpenNewAccountProfileModal() {
      this.newAccountProfileModalOpened = !this.newAccountProfileModalOpened;
      this.setPreventScrolling(true);
    },
    handleCloseNewAccountProfileModal(value) {
      this.newAccountProfileModalOpened = value.modalState;
      this.setPreventScrolling(false);
    }
  }
};
</script>

<style lang="scss">
.login--form {
  margin-bottom: 20px;
  @include respond-to(screen-sm) {
    margin-bottom: 40px;
  }
  &--input {
    width: 100%;
    display: block;
    font-size: 18px;
    margin-bottom: 20px;
    border-bottom: 1px solid $color-black;
    padding: 5px 0 5px;
    &::placeholder {
      font-size: 18px;
      color: black;
    }
    @include respond-to(screen-sm) {
      font-size: 22px;
      margin-bottom: 30px;
      padding: 10px 0 5px;
      &::placeholder {
        font-size: 22px;
        color: black;
      }
    }
    &--error {
      color: red;
      margin: 0;
      padding: 0;
    }
  }
  &--submit {
    width: 100%;
    margin-top: 10px;
    @include respond-to(screen-sm) {
      margin-top: 0;
    }
  }
}

.login {
  &--col {
    margin-bottom: 50px;
    @include respond-to(screen-sm) {
      margin-bottom: 0px;
    }
    h2 {
      @include typo(bigTit);
      margin-bottom: 15px;
      @include respond-to(screen-sm) {
        margin-bottom: 20px;
      }
    }
    p {
      margin-bottom: 30px;
    }
  }
  .col-12:first-child .login--col {
    padding-right: 0px;
    @include respond-to(screen-sm) {
      padding-right: 50px;
    }
  }
  .col-12:last-child .login--col {
    padding-left: 0;
    @include respond-to(screen-sm) {
      padding-left: 50px;
    }
  }
}

a.crAc {
  @include transition(all 300ms ease-in-out);
  text-decoration: underline;
  font-size: 16px;
  &:hover {
    opacity: 0.5;
  }
  @include respond-to(screen-sm) {
    margin-bottom: 40px;
  }
}
</style>
