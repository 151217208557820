<template lang="html">
  <div>
    <div
      class="lightbox lightbox--edit edit"
      :class="{ opened: newAccountProfileModalOpened }"
    >
      <div class="wrapper">
        <div class="lightbox__wrap">
          <div class="close-btn" @click="newAccountProfileClosed">
            <!-- <div></div> -->
          </div>
          <div class="inner">
            <newAccountForm></newAccountForm>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { authHeader } from "@/helpers/authHeader";
import newAccountForm from "@/views/Account/parts/newAccountForm";
export default {
  name: "newProfileModal",
  components: {
    newAccountForm
  },
  data() {
    return {
      collectionSucess: false
    };
  },
  props: {
    newAccountProfileModalOpened: Boolean
  },

  beforeMount() {
    this.s3RootPath = this.s3Path;
  },

  methods: {
    newAccountProfileClosed() {
      this.$emit("closeNewAccountProfileModal", {
        modalState: false
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.lightbox {
  position: fixed;
  @include center-center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  @include transition(0.3s ease-in-out);
  &__wrap {
    position: relative;
    @include transform(translateY(40px));
    @include transition(0.3s ease-in-out);
  }
  &.opened {
    opacity: 1;
    visibility: visible;
    .lightbox__wrap {
      @include transform(translateY(0));
    }
  }
}

.close-btn:hover:before {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.close-btn:hover:after {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

// .close-btn > div {
//   position: relative;
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-box-align: center;
//   -ms-flex-align: center;
//   align-items: center;
//   -webkit-box-pack: center;
//   -ms-flex-pack: center;
//   justify-content: center;
// }

.close-btn:before,
.close-btn:after {
  content: "";
  display: block;
  position: absolute;
  width: 15px;
  height: 2px;
  // background: #f6f4f0;
  background: black;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.close-btn:before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.close-btn:after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.lightbox {
  opacity: 0;
  z-index: -1;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  // background: rgba(34, 35, 35, 0.9);
  background-color: rgba($color-faintgrey, 0.5);
  -webkit-backdrop-filter: blur(10px) brightness(100%);
  backdrop-filter: blur(10px) brightness(100%);
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.lightbox.opened {
  z-index: 105;
  opacity: 1;
}

.lightbox .close-btn {
  position: absolute;
  // right: 10px;
  // top: 10px;
  //
  // @include respond-to(screen-md) {
  // 	right: 30px;
  // 	top: 30px;
  // }
}

.lightbox .close-btn:before,
.lightbox .close-btn:after {
  width: 30px;

  @include respond-to(screen-md) {
    width: 30px;
  }
}
.close-btn {
  width: 30px;
  height: 30px;
  align-items: center;
  display: flex;
  // overflow: hidden;
  cursor: pointer;

  // z-index: 99;
  z-index: 100;
  top: -40px !important;
  right: 0 !important;
}

.lightbox--video {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.lightbox--video .wrapper {
  max-width: 1080px;
  position: relative;
  width: 100%;
  padding: 20px;
  overflow: hidden;
  @include respond-to(screen-md) {
    padding: 40px;
  }
}

.lightbox--video .wrapper iframe,
.lightbox--video .wrapper video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.lightbox__wrap {
  width: 100%;
  height: auto;
  position: relative;
  // padding-top: 62.5%;
  .lightbox-vid {
    width: 100% !important;
    height: 100% !important;
    position: absolute !important;
    top: 0 !important;
  }
}

.lightbox {
  .wrapper {
    min-width: 90%;
    background-color: $color-faintgrey;
    // padding: 40px;
    color: black;
    @include respond-to(screen-md) {
      min-width: 500px;
    }
  }
}

.inner-container {
  padding: 0 20px;
  @include respond-to(screen-sm) {
    padding: 0 40px;
  }
}

.inner {
  .collection {
    &--list {
      height: 100%;
      // overflow-y: scroll;
      overflow-y: auto;
      background-color: #e6e6e6;
      list-style: none;
      margin: 0;
      padding: 0;
    }
    &--item {
      padding: 20px 18px;
      border-bottom: 1px solid $color-black;
      cursor: pointer;
      &:hover {
        background: $color-black;
        p {
          color: $color-white;
        }
      }
      &--wrapper {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        img {
          width: 44px;
          height: 44px;
        }
        p {
          @include transition(all 300ms ease-in-out);
          font-size: 16px;
          margin-left: 16px;
        }
      }
    }
    &--successful {
      height: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      align-content: center;
      h1 {
        font-family: "Graphik Compact", sans-serif;
        font-weight: 400;
        font-size: 20px;
      }
    }
  }
}

//edit lightbox style
.edit {
  &--form {
    width: 590px;
    &__input {
      display: block;
      width: 100%;
      border-bottom: 1px solid $color-black;
      padding: 10px 0 5px;
      font-size: 22px;
      margin-bottom: 40px;
    }
    &__pd {
      padding: 50px;
    }
  }
  &--item {
    label {
      font-size: 18px;
    }
  }
  &--submit {
    width: 100%;
    text-transform: uppercase;
  }
  .close-btn {
    z-index: 100;
    top: -40px;
    right: 0;
  }
}

.coll-lb {
  .wrapper {
    width: 90%;
    height: 400px;
    @include respond-to(screen-md) {
      width: 500px;
    }
  }
  .inner {
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column;
  }
  .collection--list {
    margin-bottom: 0;
    height: 100%;
  }
  .lightbox__wrap {
    height: 100%;
  }
  form {
    width: 500px;
    height: 500px;
    position: relative;
    display: flex;
    flex-flow: column;
    > div {
      position: relative;
      /* margin-bottom: auto; */
      margin: auto;
      width: 100%;
      .edit--submit {
        margin-top: auto;
      }
    }
  }
  .collection--item {
    @include transition(all 300ms ease-in-out);
  }
}
</style>
